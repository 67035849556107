.nav-wrapper {
  @include mobile-portrait {
    width: 100%;
  }
  @include mobile-landscape {
    width: 100%;
  }

  @include tablet {
    position: relative;
    justify-content: space-between;
    display: flex;
  }
  @include desktop {
    position: relative;
    width: 460px;
    display: block;
  }
}

.nav-tablet-small-open {

}

.nav-open {
  display: block;
  @include mobile-portrait {
    background: #49778D;
    background: -moz-linear-gradient(left, rgba #4C7490 0%, rgba #486B84 100%);
    background: -webkit-linear-gradient(left, rgba #4C7490 0%, rgba #486B84 100%);
    background: linear-gradient(to right, rgba #4C7490 0%, rgba #486B84 100%);
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
  }
  @include mobile-landscape {
    background: #49778D;
    background: -moz-linear-gradient(left, rgba #4C7490 0%, rgba #486B84 100%);
    background: -webkit-linear-gradient(left, rgba #4C7490 0%, rgba #486B84 100%);
    background: linear-gradient(to right, rgba #4C7490 0%, rgba #486B84 100%);
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
  }
}

.burger-menu {
  width: 40px;
  height: 30px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .palets {
    border: 2px solid;
    width: 100%;
    height: 2px;
  }
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.nav-close {
  display: block;
  @include mobile-landscape{
    display: none;
  }
  @include mobile-portrait{
    display: none;
  }
}

.nav {
  @include mobile-portrait {
    // MOBILE CSS
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .logo {
      height: auto;
      width: 100%;
      padding: 10px 20px 0px 20px;
    }
  }
  @include mobile-landscape {
    // MOBILE CSS
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    .mobile-landscape-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    }
    
    .logo {
      height: auto;
      width: 80%;
      padding: 10px 20px 0px 20px;
    }
  }
  @include tablet {
    // TABLET
    position: fixed;
    left: 20px;
    top: 20px;
    width: 200px;
    .logo {
      height: auto;
      width: 180px;
    }
  }
  @include desktop {
    // DESKTOP
    position: fixed;
    margin: auto;
    left: -600px;
    right: 0px;
    top: 5vh;
    bottom: 0px;
    width: 250px;
    .logo {
      height: auto;
      width: 220px;
      margin-bottom: 15px;
    }
  }
}


.main-nav {
  margin-top: 40px;
  @include mobile-portrait {
    margin-top: 0px;
  }
  @include mobile-landscape {
    margin-top: 0px;
  }
}

.newsletter-nav{
  a{
    color: #77933f;
    font-weight: 600;
  }
}
.sub-nav {
  margin-top: 40px;
  line-height: 20px;
  font-size: 12px;
  @include mobile-portrait {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @include mobile-landscape {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.sub-nav-link {
  a {
    font-size: 12px;
    @include mobile-portrait {
      color: white;
    }
    @include mobile-landscape {
      color: white;
    }
  }
}

.leistungen-link {
  a {
    font-size: 12px;
    color: #777777;
  }
}

.leistungen-link-green{
  a {
    font-size: 12px;
    color: #77933F;
  }
}

.bottom-nav {
  margin-top: 40px;
  @include mobile-portrait {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @include mobile-landscape {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.navBarItemWrapper {
  width: 100%;
}

.navBarItem {
  @include mobile-portrait {
    a {
      color: white;
    }
  }
  @include mobile-landscape {
    a {
      color: white;
    }
  }
}

.footer-link {
  a {
    font-size: 13px;
  }

  margin-left: 10px;
}

.navBarItemDotted {
  margin-top: 10px;
  width: 75%;
  border-bottom: 1px solid;
  border-bottom-color: lightgrey;
  @include mobile-portrait {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  @include mobile-landscape {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.active-nav {
  a {
    text-decoration: none;
    font-size: 16px;
    color: #006699;
    font-weight: 600;
  }
}

a {
  text-decoration: none;
  font-size: 16px;
  color: #777777;
  @include mobile-portrait {
  }
  @include mobile-landscape {
  }
}

video {
  width: 100%;
}