$tablet-left-right: calc((100% / 2));
$tablet-box: calc((100% / 2) - 10px);
$tablet-page: calc(100%  - 10px);

.screen-container {
  @include mobile-portrait {

    margin: 0px 20px 0px 20px;
  }
  @include mobile-landscape {
    margin: 0px 20px 0px 20px;
  }

  @include tablet {
    width: calc(100% - 220px);
    display: flex;
    flex-direction: column;
  }

  @include desktop {
    width: 800px;
    margin-left: 100px;
  }
}


.unternehmen {
  margin-top: 50px;
  @include mobile-portrait {
    .box-two {
      h2 {
        color: white;
      }
    }

  }
  @include mobile-landscape {
    .box-two {
      h2 {
        color: white;
      }
    }
  }
  @include tablet {
    position: relative;
    height: 420px;
    .box-one {
      h2 {
        color: #76933E
      }

      position: absolute;
      margin: auto;
      left: 0px;
      right: $tablet-left-right;
      bottom: 280px;
      width: $tablet-box;
      height: fit-content;
      padding-top: 0px;
      font-size: 14px;
    }
    .box-two {
      h2 {
        color: white;
      }

      padding: 5px 10px 0px 10px;
      position: absolute;
      margin: auto;
      right: 0px;
      left: $tablet-left-right;
      bottom: 260px;
      width: $tablet-box;
      height: fit-content;
      font-size: 14px;
    }
    .box-three {
      position: absolute;
      margin: auto ;
      top: 150px;
      left: 0px;
      right: $tablet-left-right;
      width: $tablet-box;
      height: fit-content;
      padding: 5px 10px 0px 10px;
      font-size: 14px;
    }
    .box-four {
      position: absolute;
      margin: auto ;
      top: 170px;
      right: 0px;
      left: $tablet-left-right;
      width: $tablet-box;
      height: fit-content;
      padding: 5px 10px 0px 10px;
      font-size: 14px;
    }
  }

  @include desktop {
    position: relative;
    height: 420px;
    .box-one {
      h2 {
        color: #76933E
      }

      h3 {
        color: #456F81
      }
      margin: auto;
      line-height: 30px;
      font-size: 16px;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 400px;
      bottom: 200px;
      height: 160px;
      width: 420px;
      padding-top: 0px;
    }
    .box-two {
      font-size: 16px;
      position: absolute;

      h2 {
        color: white;
        margin: 0px;
      }
      margin: auto;
      left: 450px;
      top: 0px;
      right: 0px;
      bottom: 140px;
      width: 350px;
      height: 175px;
      padding-top: 20px;
    }
    .box-three {
      font-size: 16px;
      position: absolute;
      margin: auto;
      top: 220px;
      left: 0px;
      right: 500px;
      bottom: 0px;
      width: 340px;
      height: auto;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .box-four {
      font-size: 16px;
      position: absolute;
      margin: auto;
      top: 260px;
      right: 0px;
      bottom: 0px;
      width: 430px;
      height: auto;
      left: 370px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
.newsletter {
  background: 	#006699;
  background: linear-gradient(to right, rgba(0,102,153,1) 0%,rgba(3,46,88,1) 100%);
  color: #fff;
  h3 {
    color: #fff;
  }
    h2 {
    color: #fff;
  }
  a {
    color: #fff;
  }
  p {
    color: #fff;
  }
  padding: 10px 10px 10px 10px;
  
  @include desktop{
    margin-top: 32px;
  }

  @include tablet{
    margin-top: 32px;
  }

  @include mobile-landscape {
    margin-top: 0px;
  }

  @include mobile-portrait{
    margin-top: 0px;
  }
}

.ansprechpartner {
  @include mobile-portrait {
    .box-one {
      span {
        font-weight: bold;
      }
    }

  }
  @include mobile-landscape {
    .box-one {
      span {
        font-weight: bold;
      }
    }
  }
  @include tablet {
    width: $tablet-page;
    h3 {
      margin: 0px;
    }
    position: relative;
    .box-wrapper {
      height: 460px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .box-one {
        position: static;
        left: 250px;
        width: 220px;
        height: 330px;
        font-weight: bold;
        padding: 20px 20px 20px 20px;
      }
    }
    .box-three {
      background: none;
      border: none;
      padding: 0px 0px 0px;
    }
  }

  @include desktop {
    position: relative;
    .box-wrapper {
      height: 460px;
      position: relative;

      .box-one {
        position: absolute;
        top: 0px;
        left: 270px;
        width: 270px;
        font-weight: bold;
      }
    }
    .box-three {
      margin-top: 10px;
      width: 800px;
      background: none;
      border: none;
    }
  }
}


.leistung {
  @include tablet {
    width: $tablet-page;
    display: flex;
    flex-direction: column;
    .box-0 {
      height: 120px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
    .box-wrapper-0 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .box-wrapper-one {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        width: $tablet-box;
        .box-1 {
          flex-direction: column;
        }

        .box-end-1 {
          align-self: flex-end;
        }

        .box-start-1 {
          display: flex;
          flex-direction: column;
        }
      }

      .box-wrapper-two {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: $tablet-box;
        .box-start {
          display: flex;
          flex-direction: column;
        }

        .box-end {
          align-self: flex-end;

        }
      }
    }
  }

  @include desktop {
    display: flex;
    flex-direction: row;
    width: 800px;
    padding-top: 20px;
    .box-0 {
      height: 120px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
    .box-wrapper-0 {
      display: flex;
      flex-direction: row;
      width: 100%;

      .box-wrapper-one {
        margin-right: 25px;
        width: 380px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;

        .box-1 {
          align-self: flex-start;
        }

        .box-end-1 {
          display: flex;
          align-self: flex-end;
          flex-direction: column;
          padding-bottom: 0px;
          width: 380px;
        }

        .box-start-1 {
          display: flex;
          flex-direction: column;
        }
      }

      .box-wrapper-two {
        margin-top: 0px;
        width: 380px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .box-start {
          display: flex;
          flex-direction: column;
        }

        .box-end {
          align-self: flex-end;
          width: 380px;
        }
      }
    }
  }
}


.partner {
  @include mobile-portrait {
    .box-one {
      background: #efefef;
      border: 1px solid #ddd;

      h2 {
        color: #444444;
      }

    }
  }
  @include mobile-landscape {
    .box-one {
      background: #efefef;
      border: 1px solid #ddd;

      h2 {
        color: #444444;
      }
    }
  }

  h3 {
    margin-bottom: 5px;
  }

  h2 {
    color: white;
  }

  @include tablet {
    align-items: center;
    display: flex;
    flex-direction: column;
    .flex-start{
      display: flex;
      width: $tablet-page;
      justify-content: flex-start;
    }

    .box-one {
      width: 275px;


    }
    .box-two {
      width: 300px;
    }
    .box-three {
    }
  }

  @include desktop {
    width: 100%;
    padding-top: 10px;
    .box-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 700px;
    }
    .box-one {
      h2 {
        padding: 5px 5px 5px 5px;
      }

      width: 280px;
    }
    .box-two {
      margin-left: 80px;
      width: 250px;
    }
    .box-three {
      margin-left: 80px;
    }
    .box-four {
      margin-top: 50px;
      margin-right: 80px;
    }
  }
}


.white {
  color: white;

}

.center-column-only {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.isMobileShown{
  display: none;
  @include mobile-portrait{
    display: block;
  }
  @include mobile-landscape{
    display: block;
  }
}

.isDesktopShown{
  display: none;
  @include desktop{
    display: block;
  }
  @include tablet{
    display: block;
  }
}


.tapingpflicht {
  h2 {
    color: white;
    margin: 0px;
    font-size: 24px;
  }
  padding: 20px 10px 10px 10px;

  @include desktop{
    margin-top: 32px;
  }

  @include tablet{
    margin-top: 32px;
  }

  @include mobile-landscape {
    margin-top: 0px;
  }

  @include mobile-portrait{
    margin-top: 0px;
  }
}