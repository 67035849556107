.ShowRoom-active {
  @include tablet {
    display: none !important;
  }
  @include desktop {
    display: none !important;
  }
}

.ShowRoom-active-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile-portrait {
    height: 200px;
    width: auto;
    margin-bottom: 10px;
  }
  @include mobile-landscape {
    height: 200px;
    width: auto;
    margin-bottom: 10px;
  }
}

.column-center-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    margin-top: 10px;
  }
}

.ShowRoom-mobile {
  @include mobile-portrait {
    margin-top: 20px;
    justify-content: space-around;
  }
  @include mobile-landscape {
    margin-top: 20px;
    justify-content: space-around;
  }
  @include tablet {
    justify-content: space-around;
  }
  @include desktop {
    width: 800px;
    justify-content: space-around;
  }
}

.ShowRoom-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.ShowRoom-preview {
  @include tablet {
    background: #efefef;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
    padding: 0px;
    position: relative;
    margin-bottom: 20px;
    width: $tablet-tripple-box;
    height: 340px;
    padding-bottom: 10px;
  }
  @include desktop {
    background: #efefef;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
    padding: 20px 20px 20px 20px;
    position: relative;
    margin-bottom: 20px;
    width: 250px;
    height: 360px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.ShowRoom-preview-pic {
  @include mobile-portrait {
    height: 80px;
    width: auto;
  }
  @include mobile-landscape {
    height: 80px;
    width: auto;

  }

  @include tablet {
    height: 180px;
    width: 128px;
    margin-top: 20px;
  }
  @include desktop {
    margin-top: 10px;
    height: 180px;
    width: auto;
    margin-bottom: 20px;
  }
}

.SinglePicture {
  @include mobile-portrait {
  }
  @include mobile-landscape {

  }
  @include tablet {
  
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.SinglePicture-pic {
  @include mobile-portrait {
  }
  @include mobile-landscape {

  }
  @include tablet {
    height: 210px;
    width: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  @include desktop {

  }
  height: 250px;
  width: auto;
}

.showroom-info {
  @include mobile-portrait {
  }
  @include mobile-landscape {

  }
  @include tablet {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  @include desktop {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 60px;
  }
}
