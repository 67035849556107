html, body, #root{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 100%;
  min-height: 100vh;
}

html {
  -webkit-box-sizing: border-box;
  scroll-behavior: smooth;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

span {
  text-align: center;
}

body {
  color: #777777;
  line-height: 1;
  font: 14px/20px "Open Sans", Helvetica, Arial, sans-serif;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.full-screen{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
  min-height: 100vh;
}

.pdf {
  width: 100%;
  height: 600px;
}

h3, h1, h2 {
  color: #444444;
}



// Small tablets (portrait view)
$screen-tablet: 1199px;

// Tablets and small desktops
$screen-desktop: 1200px;

$tablet-left-right: calc((100% / 2));
$tablet-box: calc((100% / 2) - 10px);
$tablet-page: calc(100%  - 5px);
$tablet-tripple-box: calc((100%  / 3) - 10px);

/*********************
BREAKPOINTS
*********************/

@mixin mobile-portrait {
  @media (max-width: 640px) and (orientation: portrait) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (max-width: 1000px) and (orientation: landscape) {
    @content;
  }
}

// Medium devices
@mixin tablet {
  @media (min-width: 641px) and (max-width: 1199px) and (orientation: portrait), (min-width: 1001px) and (orientation: landscape){
    @content;
  }
}


// Large devices
@mixin desktop {
  @media (min-width: #{$screen-desktop}) {
    @content;
  }
}

@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}


.app-wrapper {
    display: flex;
  @include mobile-portrait{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex: 1;
  }
  @include mobile-landscape {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  @include tablet{
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    width: 100%;
  }
  @include desktop{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
  }
}

.column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include mobile-portrait {

  }
  @include mobile-landscape {
  }

  @include tablet {
  }

  @include desktop {
    width: 800px;
  }
}
.column-center1 {
  line-height: 25px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.column-center-footer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #77933f;
  font-weight: bold;
}
.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.Hoffmann-page {
  display: flex;
  flex-direction: column;
  @include mobile-portrait{
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    .picture-size {
      width: 100%;
      height: auto;
    }
  }
  @include mobile-landscape{
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    .picture-size {
      width: 100%;
      height: auto;
    }
  }
  @include tablet{
    margin-top: 60px;
    align-items: flex-end;
    // TABLET
    .picture-size {
      width: auto;
      max-height: 600px;
    }
  }
  @include desktop{
    align-items: center;
    padding-top: 60px;
    width: 100%;
    // DESKTOP
    .picture-size {
      width: auto;
      max-height: 600px;
    }
  }
}

.only-on-big-screens {
  @include mobile-portrait{
    display: none;
  }
  @include mobile-landscape{
    display: none;
  }
  @include desktop{
    line-height: 30px;
    padding-top: 10px;
    display: flex;
    justify-self: flex-end;
  }
}

.only-on-big-screens-job {
  @include mobile-portrait{
    display: none;
  }
  @include mobile-landscape{
    display: none;
  }
  @include tablet{
    margin-top: 5px;
  }
  @include desktop{
    margin-top: 20px;
    line-height: 15px;
  }
}

.header-on-big-screens {
  @include tablet{
    display: block;
    font-size: 1.17em;
    margin-top: 15px;
    font-weight: bold;
  }

  @include desktop{
    font-size: 1.17em;
    margin-top: 10px;
    font-weight: bold;
  }
}

.text-align-center {
  text-align: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-picture {
  @include mobile-portrait{
    margin-bottom: 40px;
  }
  @include mobile-landscape{
    margin-bottom: 40px;
  }

  @include tablet {
    width: $tablet-page;
  }
  margin-top: 40px;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  @include mobile-portrait {
  }
  @include mobile-landscape {
  }

  @include tablet {
  }

  @include desktop {
    width: 800px;
  }
}

.column1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 22px;
  span {
    text-align: left;
  }
}

.background-lightgray {
  background-color: #ededed;
  border-color: #efefef;
  color: #666666;
  width: 100%;
  min-height: 60px;
  padding: 10px;
  font-size: 12px;
}
.background-gray {
  font-size: 12px;
  background-color: #dddddd;
  color: #666666;
  width: 100%;
  padding: 10px;
}


.fix-bottom {
  position: relative;
  width: 100%;
}

.space-to-top {
  margin: 50px 0px 50px 0px;
}

table{
  margin-left: -5px;
  margin-bottom: 10px;
}

.margin-top-20{
  margin-top: 20px;
  @include mobile-portrait{
    margin-top: 0px;
  }
  @include mobile-landscape{
    margin-top: 0px;
  }
  @include tablet{

  }
  @include desktop{
    width: 380px;
  }
}

.div-spacer-between-containers{
  @include mobile-portrait{

  height: 100px;
  }
  @include mobile-landscape{
  height: 100px;
  }
  @include tablet{
  height: 200px;
  }
  @include desktop{
    color: white;
   height: 350px;
  }
}

.div-spacer-between-containers-unternehmen{
  @include mobile-portrait{

  height: 20px;
  }
  @include mobile-landscape{
  height: 100px;
  }
  @include tablet{
  height: 50px;
  }
  @include desktop{
    color: white;
   height: 60px;
  }
}

.div-spacer-between-containers-partner{
  @include mobile-portrait{}
  @include mobile-landscape{

  }
  @include tablet{
height: 100px;
  }
  @include desktop{
    color: white;
    height: 350px;
  }
}

.margin-top-scroll {
  height: 40px;
}

.impressum-toggle{
  a {
    color: #777777;
  line-height: 1;
  font: 14px/20px "Open Sans", Helvetica, Arial, sans-serif;
  margin: 0px;
  padding: 0px;
  }
  ul{
    margin-top: 1px;
    margin-bottom: 1px;
    margin-left: -23px;
  }
}

.fix-top {
  position: fixed;
  left: 0px;
  background-color: white;
  z-index: 9002;
}

.fix-top-border {
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-bottom-right-radius: 40px;
}

.mobile {
  @include mobile-portrait{
    display: block;
  }
  @include mobile-landscape{
    display: block;
  }
  @include tablet{
    display: none !important;
  }
  @include desktop{
    display: none !important;
  }
}

.margin-bottom-10 {
  margin-bottom: 10px;
}
