//ie 11
$app-wrapper-id: calc( 100vh - 140px);

.full-screen{
  @include ie{
    min-height: 100vh !important;
    height: auto!important;
    display: block !important;
    .app-wrapper{
      min-height: $app-wrapper-id;
    }
  }
}

.screen-container {
  @include ie{
    display: block !important;
  }
}

.showroom-info {
  @include ie{
    width: 250px !important;
  }
}

.only-on-big-screens-job {
  @include ie{
    width: 250px !important;
    flex-wrap: wrap !important;
  }
}

.ie-showroom-wrap {
  @include ie{
    span{
        width: 250px !important;
      b {
        width: 250px !important;
        flex-wrap: wrap !important;
      }
    }
  }
}

@include ie{
  .unternehmen{
    .box-three{
      top: 210px !important;
    }
    .box-four{
      top: 250px !important;
    }
  }
}


// Edge
@supports (-ms-ime-align:auto) {
  .unternehmen{
    @include tablet{
    .box-one {
      bottom: 0px !important;
      margin:0 auto 0 auto !important;
    }
    .box-two {
      margin:0 auto 0 auto !important;
    }
      .box-three{
        margin:0 auto 0 auto !important;
        bottom: 30px !important;
      }
      .box-four{
        margin:0 auto 0 auto !important;
        bottom: 30px !important;
      }
    }
    @include desktop{

    .box-three{
      top: 170px !important;
    }
    .box-four{
      top: 190px !important;
    }
    }
  }
}
