.investment-picture{
  @include mobile-landscape{
    width: auto;
    height: 200px;
  }
  @include mobile-portrait{
    width: auto;
    height: 200px;
  }
  @include tablet{
    width: auto;
    height: 300px;
  }
  @include desktop{
    width: auto;
    height: 350px;
  }
}

.newsletter-picture{
  @include mobile-landscape{
    width: auto;
    max-height: 400px;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  @include mobile-portrait{
    width: auto;
    max-height: 320px;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  @include tablet{
    width: auto;
    max-height: 500px;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  @include desktop{
    width: auto;
    max-height: 490px;
    display: flex;
    justify-content: center;
    align-self: center;
  }
}

.box-image{
  justify-content: center;
  display: flex;
  margin-top: -10px;
}
.zurück-knopf{
  display: flex;
  justify-content: center;
  align-self: center;
  border: 1px solid lightgray;
  width: 200px;
  padding: 5px;
}
.zurück-container{
  height: 100px;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
}
