.toggle-boxblue {
  border: 1px solid #0000;
  margin: 10px;
  
}

.toggle-bluehead {
  background: 	#006699;
  background: linear-gradient(to right, rgba(0,102,153,1) 0%,rgba(3,46,88,1) 100%);
  color: #fff;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  border: 1px sold #0000;
  
  h2 {
    font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
    color: #fff;
    margin: 0px;
  }
}

.toggle-bluehead:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.toggle-bluebody {
  color: #457492;
  border-top: 1px solid #d9d9d9;
  padding-left: 10px;
  padding-right: 10px;
  p{
    font-size: 15px;
  }
  a{
    color: lightgrey;
    font-weight: 100;
  }
}

.Newsletter-links{
padding: 10px 10px 10px 10px;
  a{
    color: white;
    margin: 0px;
    font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
    font-size: 17.43px;
  }
}