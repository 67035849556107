.toggle-box {
  border: 1px solid #d9d9d9;
  margin: 10px;
}

.toggle-head {
  background-color: #f4f4f4;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  h4 {
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
    color: #77933f;
    margin: 0px;
  }
}

.toggle-head:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.toggle-body {
  color: #457492;
  border-top: 1px solid #d9d9d9;
  padding-left: 10px;
  padding-right: 10px;
}
