.contextBox {
  padding: 30px 25px 25px 25px;
  position: relative;
  margin-bottom: 20px;
  word-wrap: break-word;
  h3 {
    margin-top: 0px;
  }
}

.contextBox-gray {
  background: #efefef;
  border: 1px solid #ddd;
  ul {
    margin-top: 0px;
    padding-left: 15px;
  }
}

.contextBox-blue {
  background: 	#006699;
  background: linear-gradient(to right, rgba(0,102,153,1) 0%,rgba(3,46,88,1) 100%);
  color: #fff;
  h3 {
    color: #fff;
  }
  h2 {
    color: #fff;
  }
}

.contextBox-shadow {
  border: 0.01px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.2);
}

.contextBox-toggle {
  margin: 10px;
  border: 1px solid #d9d9d9;
  background-color: #f4f4f4;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  a {
    Font-size: 14px;
    font-weight: bold;
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
    color: #77933f;
    margin: 0px;
  }
}

.newsletter-picture{
  @include mobile-landscape{
    width: auto;
    height: 200px;
  }
  @include mobile-portrait{
    width: auto;
    height: 200px;
  }
  @include tablet{
    width: auto;
    height: 300px;
  }
  @include desktop{
    height: auto;
    width: 695px;
    margin: 10px;
  }
}