.flex-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.map-wrapper {
  position: relative;
  height: 390px;
  width: calc(100% - 40px);
  margin: 20px;
  margin-top: 80px;
}

.spacebetween {
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.kontakt-picture {
  @include mobile-portrait{
    height: 200px;
    width: 100%;
    object-fit: contain;

  }
  @include mobile-landscape{
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: right center;
  }
  @include desktop{
    margin-top: 40px;
    height: 240px;
    width: 100%;
    object-fit: cover;
    object-position: right center;
  }
  @include tablet{
    margin-top: 20px;
    height: 240px;
    width: 100%;
    object-fit: cover;
    object-position: right center;
  }
}

.kontakt-input {
  margin-left: 200px;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 5px;
  font: 14px/20px "Open Sans", Helvetica, Arial, sans-serif;
}
.kontakt-input1 {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row-reverse;
  span{
    color: red;
    font-weight: 600;
  }
  justify-content: space-between;
  margin: 10px;
}

textarea {
  resize: vertical;
}
